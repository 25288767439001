import React from "react"
import './style.scss'

const CallToAction = (props) => (
    <section className="pa-12 calltoaction">
        <div className="container">
            <div className="mb-4 calltoaction-title text-center">
                {props.title}
            </div>
            <div className="text-center">
                {props.text}
            </div>
            {/* <div>
                {props.button}
            </div> */}
        </div>
    </section>
)

export default CallToAction