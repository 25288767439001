import React from "react"
import './style.scss'

const Overlay = (props) => (
    <div className="overlay">
        <div className="overlay-text-block">
            {props.text}
        </div>
        <img className="overlay-image" src={props.image.src} alt="" />
    </div>
)

export default Overlay