import React from "react"
import Img from "gatsby-image"
import "./style.scss"

const Feature = (props) => (
    <div className="row">
        <div className="feature-left">
            <div className="feature-content px-2">
                <h3>{props.title}</h3>
                <p>{props.text}</p>
            </div>
        </div>
        <div className="feature-right pa-2">
            <Img fluid={props.image} style={{maxWidth: "620px"}} />
        </div>
    </div>
)

export default Feature