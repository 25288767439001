import React from "react"
import Social from "../Social/Social"
import Img from "gatsby-image"
import { Link } from 'gatsby'
import './style.scss'

const Footer = (props) => (
    <footer className="footer">
        <div className="footer-social mb-8">
            <Social links={props.social} />
        </div>
        <div className="footer-brand">
            <Link to="/">
                <Img fluid={props.logo}/>
            </Link>
            <p>{props.copyright}</p>
        </div>
    </footer>
)

export default Footer