import React from "react"
import Instagramicon from "../../assets/icons/instagram-icon.svg"
import Facebookicon from "../../assets/icons/facebook-icon.svg"
import Twittericon from "../../assets/icons/twitter-icon.svg"
import Linkedinicon from "../../assets/icons/linkedin-icon.svg"
import Mailicon from "../../assets/icons/email-icon.svg"

const Social = (props) => (
    <div className="social-icons">
        <a
            className="mx-1"
            href={props.links.instagram}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Instagramicon />
        </a>
        <a
            className="mx-1"
            href={props.links.facebook}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Facebookicon />
        </a>
        <a
            className="mx-1"
            href={props.links.twitter}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Twittericon />
        </a>
        <a
            className="mx-1"
            href={props.links.linkedin}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Linkedinicon />
        </a>
        <a
            className="mx-1"
            href={props.links.email}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Mailicon />
        </a>
    </div>
)

export default Social