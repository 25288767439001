import React from "react"
import Img from "gatsby-image"
import { Link } from 'gatsby'
import './style.scss'

const Header = (props) => (
    <header className="pa-4">
        <div className="container">
            <Link to="/" className="logo">
                <Img fluid={props.logo} style={{maxWidth: '200px'}} />
            </Link>
        </div>
    </header>
)

export default Header