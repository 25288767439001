import React from "react"
import Img from "gatsby-image"
import "./style.scss"

const Portfolio = (props) => (
    <div className="container">
        <div className="projects-colorBlock"></div>
        <div className="projects-title">{props.title}</div>
        <div className="projects-row">
            {
                props.projects.map(project => (
                    <a href={project.node.url} key={project.node.id} target="_blank" rel="noopener noreferrer" style={{"flex": 1}}>
                        <Img fluid={project.node.image.childImageSharp.fluid} alt={project.node.title} />
                    </a>
                ))
            }
        </div>
    </div>
)

export default Portfolio